import { useNavigation } from "@react-navigation/native";
import { getCorrectTabForOrder } from "modules/OverviewScene/OrderDetailsStateContext";
import { useScreenContext } from "navigation/context/ScreenContextProvider";
import { RouteNames } from "navigation/linkingConfig";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { AppActions } from "store/App";
import type { TabBarKey } from "store/App/constants";
import { useAppDispatch } from "store/hooks";
import { OrderSelectors } from "store/Orders";
import { getOrderInternalStatus } from "store/Orders/utils";

export function useNavigateToOrderDetails(orderUUID?: string) {
  const navigation = useNavigation();
  const { updateScreen } = useScreenContext();
  const dispatch = useAppDispatch();

  const order = useSelector((state) =>
    orderUUID ? OrderSelectors.selectById(state, orderUUID) : null
  );

  const items = useSelector((state) =>
    order?.uuid
      ? OrderSelectors.selectItemsForOrderWithCategories(state, order.uuid)
      : null
  );

  return useCallback(() => {
    if (!order || !items) {
      return;
    }
    navigation.navigate(RouteNames.Home.Main.Drawer._);
    updateScreen("overview");
    dispatch(AppActions.setSelectedOrderUUID({ orderUUID: order.uuid }));

    const orderInternalStatus = getOrderInternalStatus(order, items);

    const correctTabValue: TabBarKey =
      getCorrectTabForOrder(orderInternalStatus);

    dispatch(AppActions.setTabBar({ value: correctTabValue }));
  }, [navigation, updateScreen, dispatch, order, items]);
}
