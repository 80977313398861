import { MaterialCommunityIcons } from "@expo/vector-icons";
import { Text } from "components";
import colors from "config/colors";
import { DEFAULT_ACTIVE_OPACITY } from "config/constants";
import { DateTime } from "luxon";
import { useScreenContext } from "navigation/context/ScreenContextProvider";
import { useState } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import useHarmonicIntervalFn from "react-use/lib/useHarmonicIntervalFn";
import { useFormatDateTime } from "util/helpers";
import { ScreenInfoRow, StoreInfoRow } from "./MenuSection";
import { NotificationButton } from "./NotificationButton";
import { commonStyles } from "./styles";

function TimeRow() {
  const [currentTime, setTime] = useState(new Date());

  const formatDateTime = useFormatDateTime();

  useHarmonicIntervalFn(() => {
    setTime(new Date());
  }, 1000 * 60);

  return (
    <Text color="white" size={20} font="bold" align="center">
      {formatDateTime(currentTime, DateTime.TIME_SIMPLE)}
    </Text>
  );
}

function MenuRow() {
  const { screen, updateScreen } = useScreenContext();

  const isSwitchChecked = screen === "overview";

  return (
    <View style={styles.menuRowContainer}>
      <TouchableOpacity
        activeOpacity={DEFAULT_ACTIVE_OPACITY}
        hitSlop={8}
        style={{ marginBottom: 8 }}
      >
        <MaterialCommunityIcons
          name="cog"
          color="white"
          size={22}
          style={{ padding: 4 }}
        />
      </TouchableOpacity>
      <NotificationButton />
      <TouchableOpacity
        activeOpacity={DEFAULT_ACTIVE_OPACITY}
        hitSlop={8}
        style={{
          marginTop: 8,
          backgroundColor: colors.green,
          borderRadius: 999,
          padding: 4,
        }}
        onPress={() =>
          isSwitchChecked ? updateScreen?.("chef") : updateScreen?.("overview")
        }
      >
        {isSwitchChecked ? (
          <MaterialCommunityIcons name="view-list" color={"white"} size={22} />
        ) : (
          <MaterialCommunityIcons name="chef-hat" color={"white"} size={22} />
        )}
      </TouchableOpacity>
    </View>
  );
}

export function MenuSectionMinimized() {
  return (
    <View style={commonStyles.container}>
      <TimeRow />
      <StoreInfoRow isMinimized />
      <ScreenInfoRow isMinimized />
      <MenuRow />
    </View>
  );
}

const styles = StyleSheet.create({
  menuRowContainer: {
    alignItems: "center",
    padding: 8,
    borderRadius: 4,
    backgroundColor: colors.lightBlue2,
    marginTop: 8,
  },
});
