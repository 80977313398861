import AppLoading from "expo-app-loading";
import { lockAsync, OrientationLock } from "expo-screen-orientation";
import { useCallback, useState } from "react";
import { StatusBar } from "react-native";
import { GestureHandlerRootView } from "react-native-gesture-handler";
import { PaperProvider } from "react-native-paper";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/es/integration/react";
import "util/localization";
// import 'API/init'

import { ErrorBoundary, ToastErrorHandler } from "components";
import { ToastContainer } from "components/Snackbar";
import { AlertsContainer, AlertsProvider } from "modules/Alerts/AlertsProvider";
import Router from "navigation/RootRouter";
import { persistor, store } from "store";
import { isWeb } from "util/helpers";
import "assets/vendorCSS";
import { NavigationContainer } from "@react-navigation/native";
import { useFonts } from "assets/fonts";
import { capitalize } from "lodash";
import { NotificationStateContextProvider } from "navigation/components/SideBar/NotificationButton/NotificationStateContext";
import { ScreenContextProvider } from "navigation/context/ScreenContextProvider";
import linkingConfig from "navigation/linkingConfig";

export default function App() {
  const [isFontLoaded] = useFonts();

  const init = useCallback(async () => {
    if (!isWeb) {
      await lockAsync(OrientationLock.LANDSCAPE);
    }

    // store rehydration and startup sequence are delayed until the fonts are loaded
    persistor.persist();
  }, []);

  const [isReady, setReady] = useState(false);

  return (
    <SafeAreaProvider>
      <StatusBar
        barStyle="light-content"
        translucent
        backgroundColor="#FFFFFF00"
      />
      <ErrorBoundary
        beforeCapture={([scope], errorUUID) => {
          scope.setTag("errorUUID", errorUUID);
        }}
      >
        <ToastContainer>
          <GestureHandlerRootView style={{ flex: 1 }}>
            {isReady && isFontLoaded ? (
              <Provider store={store}>
                <PersistGate persistor={persistor}>
                  <NavigationContainer
                    linking={linkingConfig}
                    documentTitle={{
                      formatter: (options, route) =>
                        `${
                          options?.title ?? capitalize(route?.name)
                        } | Kitchen`,
                    }}
                  >
                    <NotificationStateContextProvider>
                      <ScreenContextProvider>
                        <PaperProvider>
                          <ToastErrorHandler>
                            <AlertsProvider>
                              <AlertsContainer />
                              <Router />
                            </AlertsProvider>
                          </ToastErrorHandler>
                        </PaperProvider>
                      </ScreenContextProvider>
                    </NotificationStateContextProvider>
                  </NavigationContainer>
                </PersistGate>
              </Provider>
            ) : (
              <AppLoading
                startAsync={init}
                onFinish={() => setReady(true)}
                onError={console.log}
              />
            )}
          </GestureHandlerRootView>
        </ToastContainer>
      </ErrorBoundary>
    </SafeAreaProvider>
  );
}
