import { Button, Text } from "components";
import colors from "config/colors";
import { AuthBackground } from "modules/Auth/components/AuthBackground";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ActivityIndicator, StyleSheet, View } from "react-native";
import { useSelector } from "react-redux";
import { AuthActions } from "store/Auth";
import { DeviceSelectors, DeviceThunks } from "store/Device";
import { useAppDispatch } from "store/hooks";

// technically there's a lot of code duplication between this and environment watcher,
// but soon we're going to add one more feature with similar UI - version checker (same as in dispatch app)
// I suggest we unify UI for all of them then
// -- Alex

type ContentProps = {
  titleKey: string;
  descriptionKey: string;
};

export function Content({ titleKey, descriptionKey }: ContentProps) {
  return (
    <>
      <Text id={titleKey} font="bold" style={styles.title} />
      <Text id={descriptionKey} style={styles.description} />
    </>
  );
}

export function Manager() {
  const dispatch = useAppDispatch();

  const registeredDeviceUUID = useSelector(
    DeviceSelectors.selectRegisteredUUID
  );
  const device = useSelector(DeviceSelectors.selectDevice);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>(null);
  const [counter, setCounter] = useState<number>(0);

  const { t } = useTranslation();

  const onSignOut = () => {
    dispatch(AuthActions.signOut());
  };

  useEffect(() => {
    (async () => {
      if (!registeredDeviceUUID) {
        setIsLoading(true);
        const thunkResult = await dispatch(DeviceThunks.register());
        setIsLoading(false);

        if (thunkResult.meta.requestStatus === "rejected") {
          setError(
            thunkResult.payload.errorMessage || t("errors.unexpectedError")
          );
        } else {
          setError(null);
        }
      }
    })();
  }, [dispatch, registeredDeviceUUID, counter, t]);

  useEffect(() => {
    (async () => {
      if (registeredDeviceUUID && !device) {
        setIsLoading(true);
        const thunkResult = await dispatch(DeviceThunks.fetchCurrent());
        setIsLoading(false);
        if (
          thunkResult.meta.requestStatus === "rejected" &&
          !thunkResult.payload.deviceNotFound
        ) {
          setError(
            thunkResult.payload.errorMessage || t("errors.unexpectedError")
          );
        } else {
          setError(null);
        }
      }
    })();
  }, [dispatch, registeredDeviceUUID, device, counter, t]);

  return (
    <AuthBackground>
      {error && (
        <Button
          titleId="device.manager.error.buttonSignOut"
          onPress={onSignOut}
          innerColor="white"
          color="transparent"
          variant="outline"
          iconName="chevron-left"
          style={styles.signOut}
        />
      )}
      <View style={styles.container}>
        {isLoading && !error && (
          <>
            <Content
              titleKey={"device.manager.loading.title"}
              descriptionKey={"device.manager.loading.description"}
            />
            <ActivityIndicator
              size={"large"}
              color={colors.primary}
              style={{ marginTop: 20 }}
            />
          </>
        )}
        {error && (
          <>
            <Content
              titleKey={"common.error"}
              descriptionKey={"device.manager.error.description"}
            />
            {error && (
              <Text
                color={"red"}
                align={"center"}
                style={{ marginVertical: 10 }}
              >
                {error}
              </Text>
            )}
            <Button
              iconName={"refresh"}
              titleId={"common.retry"}
              style={{ marginTop: 20, height: 50 }}
              isFetching={isLoading}
              disabled={isLoading}
              onPress={() => setCounter((currentValue) => currentValue + 1)}
            />
          </>
        )}
      </View>
    </AuthBackground>
  );
}

const styles = StyleSheet.create({
  container: {
    alignSelf: "center",
    backgroundColor: "white",
    borderRadius: 8,
    maxWidth: 400,
    padding: 16,
  },
  description: {
    fontSize: 18,
    marginBottom: 12,
    textAlign: "center",
  },
  signOut: {
    left: 16,
    position: "absolute",
    top: 16,
  },
  title: {
    fontSize: 20,
    marginVertical: 12,
    textAlign: "center",
  },
});
