import { setDeviceHeaders } from "API";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { DeviceSelectors } from "store/Device";

export function useDeviceHeaders() {
  const registeredDeviceUUID = useSelector(
    DeviceSelectors.selectRegisteredUUID
  );

  useEffect(() => {
    setDeviceHeaders(registeredDeviceUUID);
  }, [registeredDeviceUUID]);
}
