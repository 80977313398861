import { ReactNode, useMemo, useState } from "react";
import { createCtx } from "util/createCtx";

export type MainScreen = "chef" | "overview";

const [useScreenContext, ScreenProvider] = createCtx<{
  screen: MainScreen;
  updateScreen: (screen: MainScreen) => void;
}>();

export function ScreenContextProvider({ children }: { children: ReactNode }) {
  const [screen, setScreen] = useState<MainScreen>("overview");

  const screenProviderValue = useMemo(
    () => ({ screen, updateScreen: setScreen }),
    [screen]
  );

  return (
    <ScreenProvider value={screenProviderValue}>{children}</ScreenProvider>
  );
}

export { useScreenContext };
