import { createNativeStackNavigator } from "@react-navigation/native-stack";
import colors from "config/colors";
import { ChefScene } from "modules/ChefScene";
import ItemDetailView from "modules/ChefScene/ItemDetailView";
import OverviewNavigator from "modules/OverviewScene";
import { useScreenContext } from "navigation/context/ScreenContextProvider";
import { useEffect } from "react";
import { Platform, View } from "react-native";
import { useSelector } from "react-redux";
import { AppSelectors } from "store/App";
import { AnalyticsEvents, useLogEventCallback } from "util/analytics";
import { SideBar } from "./components/SideBar";
import { HideSideBarButton } from "./components/SideBar/HideSideBarButton";
import { RouteNames } from "./linkingConfig";

const Stack = createNativeStackNavigator();

const StackNavigator = () => {
  return (
    <View style={{ flex: 1 }}>
      <Stack.Navigator
        screenOptions={{
          headerShown: false,
          contentStyle: {
            backgroundColor: colors.lightBlue2,
          },
        }}
        initialRouteName={RouteNames.Home.Main.Drawer._}
      >
        <Stack.Screen
          name={RouteNames.Home.Main.Drawer._}
          component={MainView}
        />
        <Stack.Screen
          name={RouteNames.Home.Main.ItemDetails}
          component={ItemDetailView}
          options={{
            presentation: Platform.select({
              default: "transparentModal",
              ios: "containedTransparentModal",
            }),
            contentStyle: { backgroundColor: "rgba(59,58,74,0.85)" },
          }}
        />
      </Stack.Navigator>
    </View>
  );
};

const MainView = () => {
  const logEvent = useLogEventCallback();

  const { screen } = useScreenContext();

  useEffect(() => {
    switch (screen) {
      case "chef":
        logEvent(AnalyticsEvents.chefScreenEntered());
        break;
      case "overview":
        logEvent(AnalyticsEvents.overviewScreenEntered());
        break;
    }
  }, [screen]);

  const isSidebarMinimized = useSelector(AppSelectors.selectIsSidebarMinimized);

  return (
    <>
      <View style={{ flexDirection: "row", width: "100%", height: "100%" }}>
        <View
          style={{
            width: isSidebarMinimized ? 80 : 200,
            borderRightWidth: 0,
            backgroundColor: colors.darkBlue,
          }}
        >
          <SideBar />
        </View>
        <View style={{ backgroundColor: colors.lightBlue2, flex: 1 }}>
          <View style={{ width: "100%", height: "100%" }}>
            {screen === "chef" ? <ChefScene /> : <OverviewNavigator />}
          </View>
          <HideSideBarButton />
        </View>
      </View>
    </>
  );
};

export default StackNavigator;
